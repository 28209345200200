@import 'sass/vars';

.modal-resize {
  @extend %cover;

  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 54;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
  }

  &.remove {
    opacity: 0 !important;
    visibility: hidden !important;
    display: none !important;
    pointer-events: none !important;
  }

  .background {
    @extend %cover;

    background-color: black;
    pointer-events: none;
    user-select: none;
    z-index: 1;

    img {
      @extend %cover;
    }
  }

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    margin-top: 40px;
    z-index: 2;

    @media (max-width: 700px) {
      width: 100%;
      padding: 0 25px;
    }

    &-description {
      margin-top: 16px;

      p {
        color: white;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.4;
        text-align: center;
      }
    }
  }
}
