body {
  background-color: black;
  font-family: 'Gilroy';
  font-size: 16px;

  &.not-scrollable {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  @media (max-width: 1170px) {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  &::before {
    content: '';
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 600ms;
  }

  &.shadow {
    width: 100%;
    overflow: hidden;

    &::before {
      opacity: 0.6;
      pointer-events: initial;
    }
  }
}
