@import 'sass/vars';

#main-header {
  position: absolute;
  top: 65px;
  user-select: none;
  pointer-events: none;
  transition-property: left, transform;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  z-index: 55;

  @media (max-height: 760px) {
    top: 35px;
  }

  .wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &-link {
      @extend %cover;

      pointer-events: initial;
      text-indent: -999px;
      overflow: hidden;
    }

    &-icon {
      position: relative;
      margin-right: 11px;

      &--1,
      &--2 {
        display: block;
        opacity: 0;
        transition-property: opacity, transform;
        transition-duration: 1.5s;
        transition-timing-function: ease(inout);

        img {
          display: block;
        }
      }

      &--1 {
        position: relative;
        z-index: 1;
      }

      &--2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.65);
        z-index: 2;
      }
    }

    &-text {
      opacity: 0;
      transform: translateX(10px);
      transition-property: opacity, transform;
      transition-duration: time(slow);
      transition-timing-function: ease(inout);
    }
  }

  &.animate .wrapper {
    &-icon {
      &--1 {
        opacity: 1;
      }

      &--2 {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
        transition-delay: 0.15s;
      }
    }

    &-text {
      opacity: 1;
      transform: translateX(0);
      transition-delay: 0.3s;
    }
  }

  &[theme='default'] {
    left: 50%;
    transform: translateX(-50%);
  }

  &[theme='single'] {
    left: 45px;
    transform: translateX(0);
  }
}
