@import 'sass/vars';

.modal-waves-description {
  @extend %cover;

  background-color: black;
  position: fixed;
  opacity: 0;
  pointer-events: none;
  transition-property: opacity;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  overflow: hidden;
  z-index: 45;

  .close {
    position: absolute;
    top: 80px;
    right: 45px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: time(slow);
    transition-timing-function: ease(inout);
    z-index: 5;

    @media (max-height: 760px) {
      top: 50px;
    }

    button {
      display: inline-flex;
      align-items: center;
      border-bottom: 1px solid white;
      padding-bottom: 5px;
      cursor: pointer;
      transition-property: border-color;
      transition-duration: time(default);
      transition-timing-function: ease(inout);

      span {
        color: white;
        font-size: 10px;
        font-weight: 800;
        text-transform: uppercase;
        display: inline-block;
        margin-left: 8px;
        transition-property: color;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
      }

      svg path {
        transition-property: fill;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
      }

      &:hover {
        border-color: #c6bfda;

        span {
          color: #c6bfda;
        }

        svg path {
          fill: #c6bfda;
        }
      }
    }
  }

  .step {
    @extend %cover;

    z-index: 1;

    &.active {
      z-index: 2;
    }

    &-1 {
      background-color: black;
      display: flex;
      align-items: center;
      justify-content: center;

      .background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(50% - 600px);
        mix-blend-mode: hard-light;
        user-select: none;
        pointer-events: none;
        opacity: 0;
        transition-property: opacity;
        transition-duration: 1.5s;
        transition-timing-function: ease(inout);

        &.active {
          opacity: 1;
        }

        img {
          width: auto;
          height: 100%;
        }
      }

      .wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 1200px;
        max-width: calc(100% - 90px);
        margin: 0 auto;
        z-index: 2;

        &-title {
          font-size: 80px;
          font-weight: 800;
          text-align: center;
          white-space: nowrap;

          &--line {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;

            &:nth-child(1) span {
              &:nth-child(1) {
                @extend %gradient-title;

                background-image: linear-gradient(to right, #70ca19, #00a3ff);
                font-size: 100px;
                font-weight: 800;
              }

              &:nth-child(2) {
                color: #00b2ff;
                font-size: 40px;
                font-weight: 600;
                top: -15px;
                margin-left: 14px;
              }
            }

            &:nth-child(2) {
              margin: -25px 0 0 30px;

              span {
                @extend %gradient-title;

                &:nth-child(1) {
                  background-image: linear-gradient(to left, #70ca19, #00a3ff);
                  font-size: 100px;
                  font-weight: 800;
                  padding-bottom: 10px;
                }
              }
            }

            span {
              position: relative;
              opacity: 0;
              transform: translate(20px, 0);
              transition-property: opacity, transform;
              transition-duration: time(slow);
              transition-timing-function: ease(inout);
            }
          }
        }

        &-cta {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 60px;
          margin-left: 80px;
          z-index: 2;

          &--button {
            @extend %cover;

            color: red;
            text-indent: -999px;
            overflow: hidden;
            z-index: 5;
          }

          &--content {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            top: -2px;
            margin-right: -26px;
            z-index: 2;

            &--title {
              color: white;
              font-size: 14px;
              font-weight: 800;
              text-transform: uppercase;
              letter-spacing: 0.9px;
              opacity: 0;
              transform: translateX(-10px);
              transition-property: opacity, transform;
              transition-duration: time(slow);
              transition-timing-function: ease(inout);

              &:nth-child(2) {
                margin-left: 42px;
              }
            }
          }

          &--circle {
            position: relative;
            z-index: 1;

            &--element {
              position: relative;
              display: block;
              transform: rotate(180deg) scale(1.05);
              opacity: 0;
              transition-property: opacity, transform;
              transition-duration: time(slow);
              transition-timing-function: ease(inout);
              z-index: 1;

              svg {
                display: block;
                transition-property: transform;
                transition-duration: time(default);
                transition-timing-function: ease(inout);
              }
            }

            &--arrow {
              position: absolute;
              top: 50%;
              left: 50%;
              opacity: 0;
              transform: translate(calc(-50% - 10px), -50%);
              transition-property: opacity, transform;
              transition-duration: time(slow);
              transition-timing-function: ease(inout);
              z-index: 2;

              svg {
                transition-property: transform;
                transition-duration: time(default);
                transition-timing-function: ease(inout);
              }
            }
          }

          &:hover {
            .wrapper-cta--circle {
              &--element svg {
                transform: rotate(360deg) scale(1.1);
              }

              &--arrow svg {
                transform: translateX(10px);
              }
            }
          }
        }
      }
    }

    &-2 {
      display: flex;
      align-items: center;
      justify-content: center;

      .background {
        @extend %cover;

        background-color: black;
        user-select: none;
        pointer-events: none;
        opacity: 0;
        transition-property: opacity;
        transition-duration: 1.5s;
        transition-timing-function: ease(inout);
        z-index: 1;

        img {
          @extend %cover;

          opacity: 0.3;
        }
      }

      .wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 1070px;
        max-width: calc(100% - 90px);
        margin: 0 auto;
        z-index: 2;

        @media (max-height: 760px) {
          padding-top: 60px;
        }

        &-columns {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 30px;
          width: 1070px;
          margin: 0 auto 50px;

          @media (max-height: 760px) {
            margin: 0 0 35px;
          }

          .column {
            opacity: 0;
            transition-property: opacity;
            transition-duration: time(slow);
            transition-timing-function: ease(inout);

            &-description {
              p {
                color: #e4e0f0;
                font-size: 14px;
                font-weight: normal;
                line-height: 25px;

                &:not(:last-child) {
                  margin-bottom: 25px;
                }

                strong {
                  color: white;
                  font-weight: 600;
                }
              }
            }
          }
        }

        &-actions {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        &-download {
          opacity: 0;
          transition-property: opacity;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);

          a {
            display: inline-flex;
            align-items: center;
            border-bottom: 1px solid white;
            padding-bottom: 5px;
            cursor: pointer;
            transition-property: border-color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            span {
              color: white;
              font-size: 10px;
              font-weight: 800;
              text-transform: uppercase;
              display: inline-block;
              margin-right: 8px;
              transition-property: color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }

            svg path {
              transition-property: fill;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }

            &:hover {
              border-color: #c6bfda;

              span {
                color: #c6bfda;
              }

              svg path {
                fill: #c6bfda;
              }
            }
          }
        }

        &-cta {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 130px;
          z-index: 2;

          &--button {
            @extend %cover;

            color: red;
            text-indent: -999px;
            overflow: hidden;
            z-index: 5;
          }

          &--content {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            top: -2px;
            margin-right: -26px;
            z-index: 2;

            &--title {
              color: white;
              font-size: 14px;
              font-weight: 800;
              text-transform: uppercase;
              letter-spacing: 0.9px;
              opacity: 0;
              transform: translateX(-10px);
              transition-property: opacity, transform;
              transition-duration: time(slow);
              transition-timing-function: ease(inout);

              &:nth-child(2) {
                margin-left: 42px;
              }
            }
          }

          &--circle {
            position: relative;
            z-index: 1;

            &--element {
              position: relative;
              display: block;
              transform: rotate(180deg) scale(1.05);
              opacity: 0;
              transition-property: opacity, transform;
              transition-duration: time(slow);
              transition-timing-function: ease(inout);
              z-index: 1;

              svg {
                display: block;
                transition-property: transform;
                transition-duration: time(default);
                transition-timing-function: ease(inout);
              }
            }

            &--arrow {
              position: absolute;
              top: 50%;
              left: 50%;
              opacity: 0;
              transform: translate(calc(-50% - 10px), -50%);
              transition-property: opacity, transform;
              transition-duration: time(slow);
              transition-timing-function: ease(inout);
              z-index: 2;

              svg {
                transition-property: transform;
                transition-duration: time(default);
                transition-timing-function: ease(inout);
              }
            }
          }

          &:hover {
            .wrapper-cta--circle {
              &--element svg {
                transform: rotate(360deg) scale(1.1);
              }

              &--arrow svg {
                transform: translateX(10px);
              }
            }
          }
        }
      }
    }
  }

  &.animate {
    opacity: 1;
    pointer-events: initial;

    .close {
      opacity: 1;
      transition-delay: 1s;
    }

    .step {
      &-1 {
        &.active {
          .background {
            opacity: 1;
          }

          .wrapper {
            &-title {
              &--line {
                span {
                  opacity: 1;
                  transform: translateX(0);
                  transition-duration: 2s;
                }
                &:nth-child(1) span {
                  &:nth-child(1) {
                    transition-delay: 0.2s;
                  }

                  &:nth-child(2) {
                    transition-delay: 0.4s;
                  }
                }

                &:nth-child(2) {
                  span {
                    transition-delay: 0.6s;
                  }
                }
              }
            }

            &-cta {
              .wrapper-cta--content {
                &--title {
                  opacity: 1;
                  transform: translateX(0);

                  &:nth-child(1) {
                    transition-delay: 1.4s;
                  }

                  &:nth-child(2) {
                    transition-delay: 1.55s;
                  }
                }
              }

              .wrapper-cta--circle {
                &--element {
                  opacity: 1;
                  transform: rotate(0deg) scale(1);
                  transition-delay: 1.75s;
                }

                &--arrow {
                  opacity: 1;
                  transform: translate(-50%, -50%);
                  transition-delay: 1.9s;
                }
              }
            }
          }
        }
      }

      &-2 {
        &.active {
          .background {
            opacity: 1;
            transition-delay: 0.3s;
          }

          .wrapper {
            &-columns .column {
              opacity: 1;

              &:nth-child(1) {
                transition-delay: 0.6s;
              }

              &:nth-child(2) {
                transition-delay: 0.75s;
              }
            }

            &-download {
              opacity: 1;
              transition-delay: 1s;
            }

            &-cta {
              .wrapper-cta--content {
                &--title {
                  opacity: 1;
                  transform: translateX(0);

                  &:nth-child(1) {
                    transition-delay: 1.4s;
                  }

                  &:nth-child(2) {
                    transition-delay: 1.55s;
                  }
                }
              }

              .wrapper-cta--circle {
                &--element {
                  opacity: 1;
                  transform: rotate(0deg) scale(1);
                  transition-delay: 1.75s;
                }

                &--arrow {
                  opacity: 1;
                  transform: translate(-50%, -50%);
                  transition-delay: 1.9s;
                }
              }
            }
          }
        }
      }
    }
  }
}
