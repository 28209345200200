$transitions: (
  sol: 0.3s,
  fast: 0.5s,
  default: 0.7s,
  slow: 1s,
);

@function time($speed) {
  @return map-get($transitions, $speed);
}
