@import 'sass/vars';

#page-single .section-content {
  padding: 165px 0 80px;

  .background {
    @extend %cover;

    position: fixed;
    z-index: 1;
  }

  .return {
    margin-bottom: 34px;
    opacity: 0;
    transform: translateX(10px);
    transition-property: opacity, transform;
    transition-duration: 1.5s;
    transition-timing-function: ease(inout);

    a {
      display: inline-flex;
      align-items: center;
      border-bottom: 1px solid white;
      padding-bottom: 5px;
      cursor: pointer;
      transition-property: border-color;
      transition-duration: time(default);
      transition-timing-function: ease(inout);

      span {
        color: white;
        font-size: 10px;
        font-weight: 800;
        text-transform: uppercase;
        display: inline-block;
        margin-left: 8px;
        transition-property: color;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
      }

      svg path {
        transition-property: fill;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
      }

      &:hover {
        border-color: #c6bfda;

        span {
          color: #c6bfda;
        }

        svg path {
          fill: #c6bfda;
        }
      }
    }
  }

  .page-title {
    color: white;
    font-size: 18px;
    font-weight: 800;
    mix-blend-mode: overlay;
    opacity: 0;
    transform: translateX(10px);
    transition-property: opacity, transform;
    transition-duration: 1.5s;
    transition-timing-function: ease(inout);
  }

  .accordions {
    margin-top: 24px;
    padding-right: 80px;

    .accordion {
      position: relative;
      width: 100%;
      border: 1px solid fade-out(white, 0.7);
      padding: 24px;
      border-radius: 16px;
      min-height: 98px;
      opacity: 0;
      transform: translateY(10px);
      transition-property: opacity, transform;
      transition-duration: 1.5s;
      transition-timing-function: ease(inout);

      &:hover {
        z-index: 5;
      }

      &:last-child {
        .item-tooltip {
          top: unset !important;
          bottom: calc(100% - 8px);
          border-radius: 8px 8px 8px 0 !important;

          &--icon {
            bottom: 0;
            top: unset !important;
            clip-path: polygon(0 100%, 100% 0, 100% 100%) !important;
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      &-button {
        position: absolute;
        top: 24px;
        right: -55px;

        button {
          background-color: white;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          padding: 1px 1px 0 0;
          box-shadow: 0px 10px 20px rgba(0, 64, 23, 0.2);

          svg {
            transition-property: transform;
            transition-duration: time(slow);
            transition-timing-function: ease(inout);

            path {
              stroke: var(--main-color);
            }
          }
        }
      }

      &-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 48px;
        cursor: pointer;

        &--title {
          color: white;
          font-size: 24px;
          font-weight: 800;
          width: 280px;
          margin-right: 30px;
        }

        &--description {
          width: 370px;
          margin-top: 3px;
          transition-delay: 0.5s;
          transition-property: opacity;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          p {
            color: white;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.4;
          }
        }
      }

      &-wrapper {
        position: relative;
        width: 100%;
        margin-top: -60px;
        overflow: hidden;
        transition-property: height;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);
      }

      &-content {
        position: relative;
        width: 100%;
        padding-top: 16px;
        opacity: 0;
        transition-property: opacity;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);
      }

      &-flex {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .left {
          flex-shrink: 0;
          width: 235px;
          margin-top: 60px;
          margin-right: 70px;

          &-description {
            p {
              color: white;
              font-size: 12px;
              font-weight: 600;
              line-height: 1.4;
            }
          }
        }

        .center {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 300px;
          padding-right: 20px;
          margin-top: 105px;
          border-right: 1px solid fade-out(white, 0.7);
          flex-shrink: 0;
          z-index: 5;

          &::after {
            content: '';
            background-color: fade-out(white, 0.7);
            position: absolute;
            top: -63px;
            right: -1px;
            width: 1px;
            height: 63px;
          }

          .item {
            position: relative;
            display: flex;
            align-items: flex-start;
            padding-bottom: 18px;

            &:not(:last-child) {
              margin-bottom: 12px;

              &::before {
                content: '';
                background-color: fade-out(white, 0.7);
                position: absolute;
                bottom: 0;
                left: 0;
                width: 300px;
                height: 1px;
              }
            }

            &-title {
              color: white;
              font-size: 15px;
              font-weight: 800;
              max-width: 265px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            &-icon {
              position: relative;
              flex-shrink: 0;
              margin-left: 5px;
            }

            &-tooltip {
              background-color: white;
              position: absolute;
              top: 6px;
              left: calc(100% + 25px);
              width: 300px;
              border-radius: 0 8px 8px 8px;
              box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.3);
              padding: 16px;
              pointer-events: none;
              opacity: 0;
              transform: translateX(5px);
              transition-property: opacity, transform;
              transition-duration: time(default);
              transition-timing-function: ease(inout);

              &--icon {
                background-color: white;
                position: absolute;
                top: 0;
                left: -16px;
                width: 17px;
                height: 26px;
                clip-path: polygon(0 0, 100% 0, 100% 100%);
              }

              &--description {
                p {
                  color: black;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 1.4;

                  &:not(:last-child) {
                    margin-bottom: 12px;
                  }
                }
              }
            }

            &:hover {
              z-index: 2;

              .item-tooltip {
                opacity: 1;
                transform: translateX(0);
              }
            }
          }
        }

        .right {
          position: relative;
          flex-grow: 1;
          overflow: auto;
          padding-bottom: 30px;

          &::-webkit-scrollbar {
            height: 4px;
            cursor: pointer;
          }

          &::-webkit-scrollbar-track {
            background: fade-out(white, 0.7);
          }

          &::-webkit-scrollbar-thumb {
            background: white;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: white;
          }

          &-header {
            display: flex;
            align-items: center;
            padding: 0 4px;
            margin-bottom: 8px;

            &--item {
              background-color: white;
              color: var(--main-color);
              font-size: 14px;
              font-weight: 800;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              width: 371px;
              height: 34px;
              flex-shrink: 0;
              border-radius: 8px;
              padding: 1px 0 0;

              &:not(:last-child) {
                margin-right: 9px;
              }
            }
          }

          &-quarters {
            width: 1140px;
            display: flex;
            align-items: center;
            padding: 0 4px;
            padding-bottom: 16px;
            border-bottom: 1px solid fade-out(white, 0.7);

            &--item {
              background-color: fade-out(white, 0.8);
              color: white;
              font-size: 14px;
              font-weight: 800;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              width: 86px;
              height: 34px;
              padding: 1px 0 0;
              flex-shrink: 0;
              border-radius: 8px;

              &:not(:last-child) {
                margin-right: 9px;

                &::after {
                  content: '';
                  background-color: fade-out(white, 0.7);
                  position: absolute;
                  top: 0;
                  right: -5px;
                  bottom: -16px;
                  width: 1px;
                }
              }
            }
          }

          &-items {
            .item {
              position: relative;
              display: flex;
              align-items: center;

              .marker {
                position: absolute;
                top: 4px;
                bottom: 4px;
                border-radius: 8px;

                &-1 {
                  background-color: white;
                  z-index: 3;
                }

                &-2 {
                  background-color: fade-out(white, 0.7);
                  z-index: 2;
                }
              }

              .year {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-column-gap: 9px;
                position: relative;
                width: 380px;
                height: 50px;
                flex-shrink: 0;
                padding: 4px;
                border-bottom: 1px solid fade-out(white, 0.7);

                &:not(:last-child) {
                  border-right: 1px solid fade-out(white, 0.7);
                }

                &-quarter {
                  position: relative;
                  display: grid;
                  grid-template-columns: repeat(3, 1fr);
                  grid-column-gap: 4px;
                  height: 100%;

                  &:not(:last-child) {
                    &::after {
                      content: '';
                      background-color: fade-out(white, 0.7);
                      position: absolute;
                      top: -4px;
                      right: -5px;
                      bottom: -4px;
                      width: 1px;
                    }
                  }

                  &--month {
                    background-color: fade-out(white, 0.9);
                    border-radius: 8px;
                    width: 100%;
                    height: 100%;

                    &.remove {
                      opacity: 0;
                    }
                  }
                }
              }

              &:last-child .year {
                border-bottom: unset;
              }
            }
          }
        }
      }

      &.active {
        .accordion-button svg {
          transform: rotate(180deg) translateY(1px);
        }

        .accordion-header {
          &--description {
            opacity: 0;
            transition-delay: 0s;
          }
        }

        .accordion-wrapper {
          overflow: visible;
        }

        .accordion-content {
          opacity: 1;
          transition-delay: 0.5s;
        }
      }
    }
  }
}

#page-single.animate {
  .return {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 0.15s;
  }

  .page-title {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 0.3s;
  }

  .accordions .accordion {
    opacity: 1;
    transform: translateY(0);

    @for $i from 0 to 10 {
      &:nth-child(#{$i}) {
        transition-delay: 0.15s * $i + 0.3s;
      }
    }
  }
}
