@import 'sass/vars';

.modal-waves-tabs {
  @extend %cover;

  background-color: black;
  position: fixed;
  opacity: 0;
  pointer-events: none;
  transition-property: opacity;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  overflow: hidden;
  z-index: 46;

  .close {
    position: absolute;
    top: 80px;
    right: 45px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: time(slow);
    transition-timing-function: ease(inout);
    z-index: 20;

    @media (max-height: 760px) {
      top: 50px;
    }

    &.inactive {
      opacity: 0 !important;
      pointer-events: none !important;
      transition-delay: 0s !important;
    }

    button {
      display: inline-flex;
      align-items: center;
      border-bottom: 1px solid white;
      padding-bottom: 5px;
      cursor: pointer;
      transition-property: border-color;
      transition-duration: time(default);
      transition-timing-function: ease(inout);

      span {
        color: white;
        font-size: 10px;
        font-weight: 800;
        text-transform: uppercase;
        display: inline-block;
        margin-left: 8px;
        transition-property: color;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
      }

      svg path {
        transition-property: fill;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
      }

      &:hover {
        border-color: #c6bfda;

        span {
          color: #c6bfda;
        }

        svg path {
          fill: #c6bfda;
        }
      }
    }
  }

  .background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: 50% 50%;
    transform: translate(-50%, -50%) rotate(20deg);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    user-select: none;
    pointer-events: none;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1.5s;
    transition-timing-function: ease(inout);
    z-index: 1;

    &.inactive {
      opacity: 0 !important;
      pointer-events: none !important;
      transition-delay: 0s !important;
    }

    @keyframes waves_bg_image {
      0% {
        background-position: left center;
      }
      100% {
        background-position: -5524px center;
      }
    }

    &-image {
      background-size: contain;
      background-repeat: repeat;
      background-position: left center;
      position: relative;
      width: 5524px;
      height: 267px;
      animation-name: waves_bg_image;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      &:not(:last-child) {
        margin-bottom: 95px;
      }

      &:nth-child(1) {
        background-image: url('../../../assets/images/general/waves-tabs-image-1.jpg');
        animation-duration: 35s;
      }

      &:nth-child(2) {
        background-image: url('../../../assets/images/general/waves-tabs-image-2.jpg');
        animation-duration: 45s;
      }

      &:nth-child(3) {
        background-image: url('../../../assets/images/general/waves-tabs-image-3.jpg');
        animation-duration: 55s;
      }
    }
  }

  .navigation {
    @extend %cover;

    display: flex;
    align-items: center;
    justify-content: center;
    transition-property: opacity;
    transition-duration: 1.5s;
    transition-timing-function: ease(inout);
    padding-top: 50px;
    z-index: 2;

    @media (max-height: 760px) {
      padding-top: 80px;
    }

    &.inactive {
      opacity: 0 !important;
      pointer-events: none !important;
      transition-delay: 0s !important;

      .navigation-list .item {
        opacity: 0 !important;
        transform: translateY(10px) !important;
      }
    }

    &-title {
      position: absolute;
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;

      @media (max-height: 760px) {
        top: 20px;
      }

      &--line {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;

        &:nth-child(1) span {
          &:nth-child(1) {
            @extend %gradient-title;

            background-image: linear-gradient(to right, #70ca19, #00a3ff);
            font-size: 50px;
            font-weight: 800;
          }

          &:nth-child(2) {
            color: #00b2ff;
            font-size: 20px;
            font-weight: 600;
            top: -5px;
            margin-left: 8px;
          }
        }

        &:nth-child(2) {
          margin: -10px 0 0 25px;

          span {
            @extend %gradient-title;

            &:nth-child(1) {
              background-image: linear-gradient(to left, #70ca19, #00a3ff);
              font-size: 50px;
              font-weight: 800;
              padding-bottom: 10px;
            }
          }
        }

        span {
          position: relative;
          opacity: 0;
          transform: translate(10px, 0);
          transition-property: opacity, transform;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);
        }
      }
    }

    &-list {
      .item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0;
        transform: translateY(10px);
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);

        &:not(:last-child) {
          margin-bottom: 55px;

          @media (max-height: 760px) {
            margin-bottom: 35px;
          }
          @media (max-height: 680px) {
            margin-bottom: 30px;
          }
        }

        &-button {
          @extend %cover;

          text-indent: -999px;
          overflow: hidden;
          z-index: 5;
        }

        &-title {
          position: relative;
          text-align: right;
          width: 107px;

          span {
            font-size: 24px;
            font-weight: 800;
            transition-property: opacity;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            &:nth-child(1) {
              color: white;
              display: block;
            }

            &:nth-child(2) {
              @extend %gradient-title;

              background-image: linear-gradient(to right, #e9d100, #00c747);
              position: absolute;
              top: 0;
              right: 0;
              opacity: 0;
              z-index: 2;
            }
          }
        }

        &-number {
          position: relative;
          margin-left: 20px;

          span {
            @extend %gradient-title;

            font-size: 120px;
            font-weight: 800;
            transition-property: opacity;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            @media (max-height: 760px) {
              font-size: 90px;
            }
            @media (max-height: 680px) {
              font-size: 70px;
            }

            &:nth-child(1) {
              background-image: linear-gradient(to right, white, #d7d1e3 30%);
            }

            &:nth-child(2) {
              background-image: linear-gradient(to right, #00c747, #00a3ff);
              position: absolute;
              top: 0;
              left: 0;
              opacity: 0;
              z-index: 2;
            }
          }
        }

        &-arrow {
          margin-left: 20px;
          transition-property: transform;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
          position: relative;

          &::before {
            content: '';
            background-color: white;
            position: absolute;
            top: 8px;
            right: 100%;
            width: 30px;
            height: 2px;
            opacity: 0.5;
            transform-origin: 100% 50%;
            transform: scaleX(0);
            transition-property: background-color, transform;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }

          svg {
            display: block;
            opacity: 0.5;

            path {
              transition-property: fill;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }
          }
        }

        &:hover {
          .item-title,
          .item-number {
            span {
              &:nth-child(1) {
                opacity: 0;
              }

              &:nth-child(2) {
                opacity: 1;
              }
            }
          }

          .item-arrow {
            transform: translateX(30px);

            &::before {
              background-color: #00a3ff;
              transform: scaleX(1);
            }

            svg path {
              fill: #00a3ff;
            }
          }
        }
      }
    }
  }

  .year-2022,
  .year-2023,
  .year-2024 {
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 25px;
    transform-origin: 0% 50%;
    transform: translate(0, -50%);
    transition-property: transform;
    transition-delay: 2.5s;
    transition-duration: 2s;
    transition-timing-function: ease(inout);
    z-index: 1;

    .line {
      display: flex;
      align-items: center;
      transform: translateX(0);
      transition-property: transform;
      transition-duration: 3s;
      transition-timing-function: ease(inout);

      &:not(:last-child) {
        margin-bottom: -20px;
      }

      &:nth-child(2n + 0) {
        margin-left: -190px;
      }

      &-year {
        @extend %gradient-title;

        background-image: linear-gradient(to right, #00c747, #00a3ff);
        font-size: 230px;
        font-weight: 800;
        display: inline-block;
        opacity: 0;
        transition-property: opacity;
        transition-delay: 0.5s;
        transition-duration: 2s;
        transition-timing-function: ease(inout);

        &:not(:last-child) {
          margin-right: 32px;
        }
      }
    }

    &.active {
      transform: translate(0, -50%) rotate(-90deg);
      transition-delay: 0s;

      .line {
        transform: translateX(-120%);
        transition-delay: 0.8s;

        .line-year {
          opacity: 1;
          transition-delay: 0s;
        }
      }
    }
  }

  .tabs {
    @extend %cover;

    pointer-events: none;
    z-index: 4;

    .tab {
      @extend %cover;

      background-color: black;
      opacity: 0;
      transition-property: opacity;
      transition-duration: time(slow);
      transition-timing-function: ease(inout);
      z-index: 2;

      &-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;

        @media (max-height: 760px) {
          top: 20px;
        }

        &--line {
          display: flex;
          align-items: center;
          justify-content: center;

          &:nth-child(1) span {
            &:nth-child(1) {
              @extend %gradient-title;

              background-image: linear-gradient(
                to bottom right,
                #00c747,
                #00b2ff
              );
              font-size: 50px;
              font-weight: 800;
            }
          }

          &:nth-child(2) span {
            &:nth-child(1) {
              @extend %gradient-title;

              background-image: linear-gradient(to right, #70ca19, #00a3ff);
              font-size: 20px;
              font-weight: 800;
              margin-right: 6px;
            }

            &:nth-child(2) {
              color: #00b2ff;
              font-size: 12px;
              font-weight: 600;
              margin-right: 6px;
            }

            &:nth-child(3) {
              @extend %gradient-title;

              background-image: linear-gradient(to left, #70ca19, #00a3ff);
              font-size: 20px;
              font-weight: 800;
              padding-bottom: 5px;
            }
          }

          span {
            position: relative;
            opacity: 0;
            transform: translate(10px, 0);
            transition-property: opacity, transform;
            transition-duration: time(slow);
            transition-timing-function: ease(inout);
          }
        }
      }

      @keyframes waves_bar {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-200vw);
        }
      }

      &-bar {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 23px;
        transform: translateX(-140vw);
        animation-name: waves_bar;
        animation-duration: 15s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        z-index: 4;

        &--1,
        &--2,
        &--3 {
          position: relative;
          width: 100vw;
          height: 100%;
          flex-shrink: 0;
          background: linear-gradient(
            90deg,
            #ffc804 0%,
            #70ca19 9.87%,
            #00a3ff 30.78%,
            #a824f8 50.9%,
            #ffc804 65.71%,
            #ff5e1c 75.22%,
            #ff151e 85.21%,
            #ff2567 96.12%
          );
          display: block;
        }

        &--2 {
          transform: scaleX(-1);
        }

        &--3 {
          margin-left: -1px;
        }
      }

      &-flex {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1070px;
        max-height: 100%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: space-between;
        z-index: 1;

        @media (max-height: 680px) {
          padding-top: 50px;
        }
      }

      &-left {
        width: 530px;

        &--title {
          color: white;
          font-size: 80px;
          font-weight: 800;
          opacity: 0;
          transition-property: opacity;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);

          @media (max-height: 760px) {
            font-size: 70px;
          }
          @media (max-height: 680px) {
            font-size: 60px;
          }
        }

        &--description {
          width: 410px;
          margin-top: 15px;
          opacity: 0;
          transition-property: opacity;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);

          p {
            color: #c6bfda;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.8;
          }
        }

        &--group {
          margin-top: 40px;

          &_title {
            color: white;
            font-size: 12px;
            font-weight: 800;
            letter-spacing: 1.8px;
            text-transform: uppercase;
            opacity: 0;
            transition-property: opacity;
            transition-duration: time(slow);
            transition-timing-function: ease(inout);
          }

          &_items {
            display: flex;
            align-items: center;
            margin-top: 24px;

            .item {
              background-color: white;
              display: flex;
              align-items: center;
              text-align: left;
              position: relative;
              height: 48px;
              border-radius: 4px;
              padding: 1px 16px 0 24px;
              opacity: 0;
              transform: translateX(10px);
              transition-property: opacity, transform;
              transition-duration: time(slow);
              transition-timing-function: ease(inout);

              &:not(:last-child) {
                margin-right: 16px;
              }

              &-bar {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 8px;
                border-radius: 4px 0 0 4px;
              }

              &-title {
                font-size: 14px;
                font-weight: 800;
              }
            }
          }
        }
      }

      &-right {
        width: 410px;
        padding-top: 10px;
        opacity: 0;
        transition-property: opacity;
        transition-duration: 1.5s;
        transition-timing-function: ease(inout);

        &--title {
          color: white;
          font-size: 12px;
          font-weight: 800;
          text-transform: uppercase;
          letter-spacing: 1.8px;
          margin-bottom: 24px;
        }

        &--description {
          padding-right: 15px;
          max-height: 50vh;
          overflow: auto;

          > * {
            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }

          &::-webkit-scrollbar {
            width: 3px;
          }

          &::-webkit-scrollbar-track {
            background: fade-out(white, 0.8);
          }

          &::-webkit-scrollbar-thumb {
            background: #c6bfda;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #c6bfda;
          }

          p,
          ul li {
            color: #c6bfda;
            font-size: 14px;
            font-weight: 800;
            line-height: 1.8;
          }

          ul li {
            position: relative;
            padding-left: 15px;

            &:not(:last-child) {
              margin-bottom: 5px;
            }

            &::before {
              content: '';
              background-color: #c6bfda;
              position: absolute;
              top: 8px;
              left: 0;
              width: 5px;
              height: 5px;
              border-radius: 50%;
            }
          }
        }

        &--cta {
          display: inline-flex;
          align-items: center;
          border-bottom: 1px solid white;
          padding-bottom: 5px;
          cursor: pointer;
          transition-property: border-color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
          margin-top: 24px;

          span {
            color: white;
            font-size: 10px;
            font-weight: 800;
            text-transform: uppercase;
            display: inline-block;
            margin-right: 8px;
            transition-property: color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }

          svg path {
            transition-property: fill;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }

          &:hover {
            border-color: #c6bfda;

            span {
              color: #c6bfda;
            }

            svg path {
              fill: #c6bfda;
            }
          }
        }
      }

      &.active {
        opacity: 1;
        pointer-events: initial;
        z-index: 3;

        .tab-title {
          &--line {
            span {
              opacity: 1;
              transform: translateX(0);
            }

            &:nth-child(2) span {
              &:nth-child(1) {
                transition-delay: 0.15s;
              }

              &:nth-child(2) {
                transition-delay: 0.3s;
              }

              &:nth-child(3) {
                transition-delay: 0.45s;
              }
            }
          }
        }

        .tab-left {
          &--title {
            opacity: 1;
            transition-delay: 0.4s;
          }

          &--description {
            opacity: 1;
            transition-delay: 0.55s;
          }

          &--group {
            &_title {
              opacity: 1;
              transition-delay: 0.7s;
            }

            &_items .item {
              opacity: 1;
              transform: translateX(0);

              &:nth-child(1) {
                transition-delay: 0.85s;
              }

              &:nth-child(2) {
                transition-delay: 1s;
              }

              &:nth-child(3) {
                transition-delay: 1.15s;
              }

              &:nth-child(4) {
                transition-delay: 1.3s;
              }
            }
          }
        }

        .tab-right {
          opacity: 1;
          transition-delay: 1.3s;
        }
      }
    }
  }

  &.animate {
    opacity: 1;
    pointer-events: initial;

    .close {
      opacity: 1;
      transition-delay: 1s;
    }

    .background {
      opacity: 0.3;
      transition-delay: 1s;
    }

    .navigation {
      &-title {
        &--line {
          span {
            opacity: 1;
            transform: translateX(0);
          }

          &:nth-child(1) span {
            &:nth-child(1) {
              transition-delay: 0.15s;
            }

            &:nth-child(2) {
              transition-delay: 0.3s;
            }
          }

          &:nth-child(2) span {
            transition-delay: 0.45s;
          }
        }
      }

      &-list .item {
        opacity: 1;
        transform: translateY(0);

        &:nth-child(1) {
          transition-delay: 0.45s;
        }

        &:nth-child(2) {
          transition-delay: 0.6s;
        }

        &:nth-child(3) {
          transition-delay: 0.75s;
        }
      }
    }
  }
}
