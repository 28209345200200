@import 'sass/vars';

#page-home .tabs {
  @extend %cover;

  background-size: 800% 800%;
  background-position: 0% 0%;
  transition-property: background-position;
  transition-duration: 2s;
  transition-timing-function: ease(inout);
  z-index: 2;

  @for $i from 1 to 9 {
    &[data-background='#{$i}'] {
      background-position: 14.28% * $i - 14.28% 14.28% * $i - 14.28%;
    }
  }

  .title,
  .description,
  .cta {
    opacity: 0;
    transition-property: opacity;
    transition-duration: time(slow);
    transition-timing-function: ease(inout);
    pointer-events: none;
    z-index: 2;

    &.active {
      opacity: 1;
      pointer-events: initial;
      z-index: 3;
    }
  }

  .title {
    position: absolute;
    top: calc(50% - 110px);
    left: calc(50% + 60px);
    width: 520px;
    transform: translate(-50%, -50%);
    mix-blend-mode: overlay;
    white-space: nowrap;

    span {
      color: white;
      font-size: 80px;
      font-weight: 300;

      &:last-child {
        font-weight: 800;
      }

      s {
        position: relative;
        display: inline-block;
        opacity: 0;
        transform: translate(20px, 0);
        transition-property: opacity, transform;
        transition-duration: 1.5s;
        transition-timing-function: ease(inout);

        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }

    &.active span {
      s {
        opacity: 1;
        transform: translate(0, 0);
      }

      &:nth-child(1) s {
        &:nth-child(1) {
          transition-delay: 0.3s;
        }

        &:nth-child(2) {
          transition-delay: 0.45s;
        }
      }

      &:last-child s {
        &:nth-child(1) {
          transition-delay: 0.45s;
        }

        &:nth-child(2) {
          transition-delay: 0.6s;
        }

        &:nth-child(3) {
          transition-delay: 0.6s;
        }
      }
    }
  }

  .description {
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% + 60px);
    width: 520px;
    padding-left: 110px;
    transform: translate(-50%, 0);

    p {
      color: white;
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;

      strong {
        font-weight: bold;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &.active {
      transition-delay: 0.7s;
    }
  }

  .cta {
    position: absolute;
    right: 90px;
    bottom: 55px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-button {
      @extend %cover;

      text-indent: -999px;
      overflow: hidden;
      z-index: 5;
    }

    &-title {
      position: relative;
      color: white;
      font-size: 14px;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 0.9px;
      margin-right: -26px;
      opacity: 0;
      transform: translateX(-15px);
      transition-property: opacity, transform;
      transition-duration: time(slow);
      transition-timing-function: ease(inout);
      z-index: 2;
    }

    &-circle {
      position: relative;
      z-index: 1;

      &--element {
        position: relative;
        transform: rotate(180deg) scale(1.05);
        opacity: 0;
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);
        z-index: 1;

        svg {
          display: inline-block;
          transition-property: transform;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
        }
      }

      &--arrow {
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0;
        transform: translate(calc(-50% - 10px), -50%);
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);
        z-index: 2;

        svg {
          transition-property: transform;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
        }
      }
    }

    &:hover {
      .cta-circle {
        &--element svg {
          transform: rotate(360deg) scale(1.1);
        }

        &--arrow svg {
          transform: translateX(10px);
        }
      }
    }

    &.active {
      transition-delay: 0.5s;

      .cta-title {
        opacity: 1;
        transform: translateX(0);
      }

      .cta-circle {
        &--element {
          opacity: 1;
          transform: rotate(0deg) scale(1);
          transition-delay: 0.7s;
        }

        &--arrow {
          opacity: 1;
          transform: translate(-50%, -50%);
          transition-delay: 0.6s;
        }
      }
    }
  }
}
