@import 'sass/vars';

#main-preloader {
  @extend %cover;

  background-color: black;
  position: fixed;
  opacity: 1;
  transition-property: opacity;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  z-index: 50;

  &.jump {
    z-index: 500;
  }

  &.remove {
    opacity: 0;
    pointer-events: none;
  }

  @keyframes logo_opacity {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    animation-name: logo_opacity;
    animation-duration: 0.7s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: ease(inout);
    z-index: 5;

    &-icon {
      position: relative;
      margin-right: 11px;

      &--1,
      &--2 {
        display: block;

        img {
          display: block;
        }
      }

      &--1 {
        position: relative;
        z-index: 1;
      }

      &--2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.65);
        z-index: 2;
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% + 2px);
    transform: translate(-50%, -50%);
    z-index: 2;

    &-title {
      background-position: center center;
      background-size: cover;
      font-size: 80px;
      font-weight: 800;
      text-align: center;
      white-space: nowrap;
      position: relative;

      span {
        @extend %gradient-title;

        opacity: 0;
        transform: translate(20px, 0);
        transition-property: opacity, transform;
        transition-duration: 2s;
        transition-timing-function: ease(inout);
        padding-bottom: 10px;
      }

      &:nth-child(1) {
        span {
          &:nth-child(1) {
            background-image: linear-gradient(to right, #ff5e1c, #ff7218);
          }

          &:nth-child(2) {
            background-image: linear-gradient(to right, #ff7218, #ff8a12);
            transition-delay: 0.1s;
          }

          &:nth-child(3) {
            background-image: linear-gradient(to right, #ff8a12, #ffbf04);
            transition-delay: 0.2s;
          }

          &:nth-child(4) {
            background-image: linear-gradient(to right, #ffbf04, #ffc805);
            transition-delay: 0.3s;
          }
        }
      }

      &:nth-child(2) {
        left: -135px;

        span {
          &:nth-child(1) {
            background-image: linear-gradient(to right, #ff5e1c, #ff423f);
            transition-delay: 1.5s;
          }

          &:nth-child(2) {
            background-image: linear-gradient(to right, #ff423f, #ff2467);
            transition-delay: 1.6s;
          }

          &:nth-child(3) {
            background-image: linear-gradient(to right, #ff2467, #ff2467);
            transition-delay: 1.7s;
          }

          &:nth-child(4) {
            background-image: linear-gradient(to right, #ff2467, #ff2567);
            transition-delay: 1.8s;
          }
        }
      }

      &:nth-child(3) {
        margin-top: -10px;
        right: -25px;

        span {
          &:nth-child(1) {
            background-image: linear-gradient(to right, #70ca19, #2eb3a2);
            transition-delay: 1.9s;
          }

          &:nth-child(2) {
            background-image: linear-gradient(to right, #2eb3a2, #1cadc6);
            transition-delay: 2s;
          }

          &:nth-child(3) {
            background-image: linear-gradient(to right, #1cadc6, #01a3fb);
            transition-delay: 2.1s;
          }

          &:nth-child(4) {
            background-image: linear-gradient(to right, #01a3fb, #00a3ff);
            transition-delay: 2.2s;
          }
        }
      }
    }
  }

  .cta {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    @media (max-height: 760px) {
      bottom: 30px;
    }

    &-button {
      @extend %cover;

      text-indent: -999px;
      overflow: hidden;
      z-index: 5;
    }

    &-content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      top: -2px;
      margin-right: -26px;
      z-index: 2;

      &--title {
        color: white;
        font-size: 14px;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 0.9px;
        opacity: 0;
        transform: translateX(-10px);
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);

        &:nth-child(2) {
          margin-left: 42px;
        }
      }
    }

    &-circle {
      position: relative;
      z-index: 1;

      &--element {
        position: relative;
        display: block;
        transform: rotate(180deg) scale(1.05);
        opacity: 0;
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);
        z-index: 1;

        svg {
          display: block;
          transition-property: transform;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
        }
      }

      &--arrow {
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0;
        transform: translate(calc(-50% - 10px), -50%);
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);
        z-index: 2;

        svg {
          transition-property: transform;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
        }
      }
    }

    &:hover {
      .cta-circle {
        &--element svg {
          transform: rotate(360deg) scale(1.1);
        }

        &--arrow svg {
          transform: translateX(10px);
        }
      }
    }
  }

  &.animate {
    .wrapper {
      &-title span {
        opacity: 1;
        transform: translate(0, 0);
      }
    }

    .cta {
      .cta-content {
        &--title {
          opacity: 1;
          transform: translateX(0);

          &:nth-child(1) {
            transition-delay: 2.85s;
          }

          &:nth-child(2) {
            transition-delay: 3s;
          }
        }
      }

      .cta-circle {
        &--element {
          opacity: 1;
          transform: rotate(0deg) scale(1);
          transition-delay: 3.2s;
        }

        &--arrow {
          opacity: 1;
          transform: translate(-50%, -50%);
          transition-delay: 3.35s;
        }
      }
    }
  }
}
