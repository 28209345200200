@import 'sass/vars';

#page-single .actions {
  display: flex;
  align-items: center;
  position: absolute;
  top: 80px;
  right: 45px;
  z-index: 3;

  @media (max-height: 760px) {
    top: 50px;
  }

  .action {
    opacity: 0;
    transform: translateX(10px);
    transition-duration: time(slow);
    transition-timing-function: ease(inout);

    &:not(:last-child) {
      margin-right: 122px;
    }

    button,
    a {
      display: inline-flex;
      align-items: center;
      border-bottom: 1px solid white;
      padding-bottom: 5px;
      cursor: pointer;
      transition-property: border-color;
      transition-duration: time(default);
      transition-timing-function: ease(inout);

      span {
        color: white;
        font-size: 10px;
        font-weight: 800;
        text-transform: uppercase;
        display: inline-block;
        transition-property: color;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
      }

      svg {
        margin-left: 8px;

        path {
          transition-property: fill;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
        }
      }

      &:hover {
        border-color: darken(white, 10%);

        span {
          color: darken(white, 10%);
        }

        svg path {
          fill: darken(white, 10%);
        }
      }
    }
  }
}

#page-single.animate .actions {
  .action {
    opacity: 1;
    transform: translateX(0);

    &:nth-child(1) {
      transition-delay: 0.8s;
    }

    &:nth-child(2) {
      transition-delay: 0.95s;
    }
  }
}
