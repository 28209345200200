@import 'sass/vars';

.modal-description {
  @extend %cover;

  background-color: black;
  position: fixed;
  opacity: 1;
  transition-property: opacity;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  z-index: 45;

  &.remove {
    opacity: 0;
    pointer-events: none;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1070px;

    @media (max-height: 760px) {
      padding-bottom: 50px;
    }

    &-column {
      opacity: 0;
      transition-property: opacity;
      transition-duration: 2s;
      transition-timing-function: ease(inout);

      &--description {
        p {
          color: #c6bfda;
          font-size: 14px;
          font-weight: normal;
          line-height: 25px;

          &:not(:last-child) {
            margin-bottom: 25px;
          }

          strong {
            color: white;
            font-weight: 600;
          }
        }
      }

      &--cta {
        margin-top: 25px;

        a {
          display: inline-flex;
          align-items: center;
          border-bottom: 1px solid white;
          padding-bottom: 5px;
          cursor: pointer;
          transition-property: border-color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          span {
            color: white;
            font-size: 10px;
            font-weight: 800;
            text-transform: uppercase;
            display: inline-block;
            margin-right: 8px;
            transition-property: color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }

          svg path {
            transition-property: fill;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }

          &:hover {
            border-color: #c6bfda;

            span {
              color: #c6bfda;
            }

            svg path {
              fill: #c6bfda;
            }
          }
        }
      }
    }
  }

  .cta {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    @media (max-height: 760px) {
      bottom: 30px;
    }

    &-button {
      @extend %cover;

      text-indent: -999px;
      overflow: hidden;
      z-index: 5;
    }

    &-content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      top: -2px;
      margin-right: -26px;
      z-index: 2;

      &--title {
        color: white;
        font-size: 14px;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 0.9px;
        opacity: 0;
        transform: translateX(-10px);
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);

        &:nth-child(2) {
          margin-left: 42px;
        }
      }
    }

    &-circle {
      position: relative;
      z-index: 1;

      &--element {
        position: relative;
        display: block;
        transform: rotate(180deg) scale(1.05);
        opacity: 0;
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);
        z-index: 1;

        svg {
          display: block;
          transition-property: transform;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
        }
      }

      &--arrow {
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0;
        transform: translate(calc(-50% - 10px), -50%);
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);
        z-index: 2;

        svg {
          transition-property: transform;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
        }
      }
    }

    &:hover {
      .cta-circle {
        &--element svg {
          transform: rotate(360deg) scale(1.1);
        }

        &--arrow svg {
          transform: translateX(10px);
        }
      }
    }
  }

  &.animate {
    opacity: 1;
    pointer-events: initial;

    .wrapper {
      &-column {
        opacity: 1;

        &:nth-child(1) {
          transition-delay: 0.5s;
        }

        &:nth-child(2) {
          transition-delay: 0.7s;
        }
      }
    }

    .cta {
      .cta-content {
        &--title {
          opacity: 1;
          transform: translateX(0);

          &:nth-child(1) {
            transition-delay: 1.4s;
          }

          &:nth-child(2) {
            transition-delay: 1.55s;
          }
        }
      }

      .cta-circle {
        &--element {
          opacity: 1;
          transform: rotate(0deg) scale(1);
          transition-delay: 1.75s;
        }

        &--arrow {
          opacity: 1;
          transform: translate(-50%, -50%);
          transition-delay: 1.9s;
        }
      }
    }
  }
}
