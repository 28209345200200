@import 'sass/vars';

#page-login .section-login {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .background {
    @extend %cover;

    background-color: black;
    pointer-events: none;
    user-select: none;
    z-index: 1;

    img {
      @extend %cover;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
    position: relative;
    z-index: 2;

    &-title {
      color: white;
      font-size: 32px;
      font-weight: 800;
      opacity: 0;
      transform: translateY(10px);
      transition-property: opacity, transform;
      transition-duration: 1.5s;
      transition-timing-function: ease(inout);
    }

    &-form {
      width: 300px;
      margin-top: 80px;
      opacity: 0;
      transition-property: opacity;
      transition-duration: 1.5s;
      transition-timing-function: ease(inout);

      @media (max-height: 760px) {
        margin-top: 50px;
      }

      .field-group {
        position: relative;
        width: 100%;
        margin-bottom: 40px;

        @media (max-height: 760px) {
          margin-bottom: 25px;
        }

        .label {
          color: #c6bfda;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          display: block;
          letter-spacing: 1px;
          margin-bottom: 10px;
        }

        .field {
          background-color: transparent;
          position: relative;
          color: white;
          font-size: 16px;
          font-weight: 500;
          padding-bottom: 10px;
          width: 100%;
          display: block;
          border: none;
          border-bottom: 1px solid white;

          &::placeholder {
            color: white;
            opacity: 0.5;
          }
        }
      }
    }

    &-cta {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 65px;
      opacity: 0;
      transform: translateY(10px);
      transition-property: opacity, transform;
      transition-duration: 1.5s;
      transition-timing-function: ease(inout);
      z-index: 2;

      @media (max-height: 760px) {
        margin-top: 30px;
      }

      &--button {
        @extend %cover;

        text-indent: -999px;
        overflow: hidden;
        z-index: 5;
      }

      &--content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        top: -2px;
        margin-right: -26px;
        z-index: 2;

        &_title {
          color: white;
          font-size: 14px;
          font-weight: 800;
          text-transform: uppercase;
          letter-spacing: 0.9px;

          &:nth-child(2) {
            margin-left: 42px;
          }
        }
      }

      &--circle {
        position: relative;
        z-index: 1;

        &_element {
          position: relative;
          display: block;
          z-index: 1;

          svg {
            display: block;
            transition-property: transform;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }
        }

        &_arrow {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;

          svg {
            transition-property: transform;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }
        }
      }

      &:hover {
        .wrapper-cta--circle {
          &_element svg {
            transform: rotate(360deg) scale(1.1);
          }

          &_arrow svg {
            transform: translateX(10px);
          }
        }
      }
    }
  }

  &.animate .wrapper {
    &-title {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.5s;
    }

    &-form {
      opacity: 1;
      transition-delay: 0.65s;
    }

    &-cta {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.8s;
    }
  }
}
