@import 'sass/vars';

#page-single {
  position: relative;
  width: 100%;

  .container {
    position: relative;
    width: 1290px;
    max-width: calc(100% - 90px);
    margin: 0 auto;
    z-index: 2;

    @media (max-width: 1300px) {
      padding: 0 25px;
    }
  }
}
