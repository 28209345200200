@import 'sass/vars';

#page-home .navigation {
  position: absolute;
  top: calc(50% + 40px);
  left: 0;
  transform: translateY(-50%);
  z-index: 3;

  &-circle,
  &-line {
    user-select: none;

    img {
      display: block;
    }
  }

  &-circle {
    position: relative;
    opacity: 0.5;
    width: 336px;
    height: 336px;
    display: flex;
    justify-content: flex-end;
    transform: translateX(-50%) rotate(-180deg);
    transition-property: transform;
    transition-duration: 2s;
    transition-timing-function: ease(inout);
    z-index: 1;
  }

  &-line {
    position: absolute;
    top: 50%;
    left: 0;
    transition-property: transform;
    transition-duration: time(slow);
    transition-timing-function: ease(inout);

    img {
      opacity: 0;
      transform-origin: 50% 50%;
      transform: scale(1.05);
      transition-property: opacity, transform;
      transition-duration: time(slow);
      transition-timing-function: ease(inout);
    }

    &[data-position='0'] {
      transform: translate(-50%, -50%) rotate(-23deg);
    }

    &[data-position='1'] {
      transform: translate(-50%, -50%) rotate(0);
    }

    &[data-position='2'] {
      transform: translate(-50%, -50%) rotate(23deg);
    }

    &[data-position='3'] {
      transform: translate(-50%, -50%) rotate(46deg);
    }

    &[data-position='4'] {
      transform: translate(-50%, -50%) rotate(69deg);
    }

    &[data-position='5'] {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &[data-position='6'] {
      transform: translate(-50%, -50%) rotate(111deg);
    }

    &[data-position='7'] {
      transform: translate(-50%, -50%) rotate(132deg);
    }

    &[data-position='8'] {
      transform: translate(-50%, -50%) rotate(158deg);
    }
  }

  &-content {
    position: absolute;
    top: 50%;
    left: 0;
    width: 455px;
    height: 455px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    transform: translate(-150px, -50%) rotate(-180deg);
    transition-property: transform;
    transition-duration: 2s;
    transition-timing-function: ease(inout);

    .item {
      cursor: pointer;
      opacity: 0;
      transition-property: opacity;
      transition-duration: 2s;
      transition-timing-function: ease(inout);

      &-title {
        transform: rotate(180deg);
        transition-delay: 0.3s;
        transition-property: transform;
        transition-duration: 2s;
        transition-timing-function: ease(inout);

        &--transform {
          position: relative;
          transform: translate(10px, -10px);
          transition-property: transform;
          transition-duration: 1.5s;
          transition-timing-function: ease(inout);

          span {
            color: white;
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 1px;
            display: block;
            text-align: left;
            opacity: 0.5;
            transition-property: opacity;
            transition-duration: time(slow);
            transition-timing-function: ease(inout);
          }
        }
      }

      &.active,
      &:hover {
        .item-title--transform span {
          opacity: 1;
        }
      }

      &:nth-child(1) {
        margin-right: 220px;
        margin-bottom: 5px;
        transition-delay: 0.75s;

        .item-title {
          &--transform {
            transition-delay: 1.35s;
          }
        }
      }

      &:nth-child(2) {
        margin-right: 87px;
        margin-bottom: 52px;
        transition-delay: 0.7s;

        .item-title {
          &--transform {
            transition-delay: 1.3s;
          }
        }
      }

      &:nth-child(3) {
        margin-right: 36px;
        margin-bottom: 61px;
        transition-delay: 0.65s;

        .item-title {
          &--transform {
            transition-delay: 1.25s;
          }
        }
      }

      &:nth-child(4) {
        margin-right: 36px;
        margin-bottom: 63px;
        transition-delay: 0.6s;

        .item-title {
          &--transform {
            transition-delay: 1.2s;
          }
        }
      }

      &:nth-child(5) {
        margin-right: 0;
        margin-bottom: 62px;
        transition-delay: 0.55s;

        .item-title {
          &--transform {
            transition-delay: 1.15s;
          }
        }
      }

      &:nth-child(6) {
        margin-right: 39px;
        margin-bottom: 39px;
        transition-delay: 0.5s;

        .item-title {
          &--transform {
            transition-delay: 1.1s;
          }
        }
      }

      &:nth-child(7) {
        margin-right: 82px;
        margin-bottom: 13px;
        transition-delay: 0.45s;

        .item-title {
          &--transform {
            transition-delay: 1.05s;
          }
        }
      }

      &:nth-child(8) {
        margin-right: 189px;
        transition-delay: 0.4s;

        .item-title {
          &--transform {
            transition-delay: 1s;
          }
        }
      }
    }
  }
}

#page-home.animate .navigation {
  &-circle {
    transform: translateX(-50%) rotate(0deg);
  }

  &-line img {
    opacity: 1;
    transform: scale(1);
    transition-delay: 1.5s;
  }

  &-content {
    transform: translate(-150px, -50%) rotate(0);
    transition-delay: 0.3s;

    .item {
      opacity: 1;

      .item-title {
        transform: rotate(0deg);

        &--transform {
          transform: translate(0, 0);
          transition-delay: 1s;
        }
      }
    }
  }
}
